<template>
  <div>
    <div class="header">
       <van-icon  @click="$router.go(-1)" size=".35rem" name="arrow-left"/>
       <div class="title">个人头像</div>
       <div style="margin-top:-0.2rem;font-size:0.5rem;" @click="editava" class="title">...</div>
    </div>
    <div style="text-align:center;margin-top:1rem;">
      <div v-if="avatar == '' ">
        <img v-if="sex == '女'" class="imgstyle" @click="editava"  src="../../assets/nv.jpg" alt="">
        <img v-else class="imgstyle" @click="editava"  src="../../assets/user_1.jpg" alt="">
      </div>
       <div v-else>
          <img   class="imgstyle" @click="editava"  :src="avatar" alt="">
       </div>
      
    </div>
    <van-popup position="bottom" v-model="show" :style="{ height: '30%' }">
      <div class="tab" style="margin-top:0.48rem;">
        <van-uploader :after-read="afterRead" >
          <van-button   style="padding-bottom:0.4rem;margin-top:0.48rem;margin-bottom:0.48rem;border:none;font-size: 0.34rem;" >从手机相册选择</van-button>
         </van-uploader>
      </div> 
    </van-popup>
  </div>
</template>
<script>
import upfilemeth from "../../util/upfilejs"
import downloadfile from "../../util/downloadfile"
import { Toast } from 'vant'
export default {
  data(){
    return {
      avatar:"",
      show:false,
      actions: [{ name: '从手机相册选择',type:0 }, { name: '保存图片',type:1 }],
      sex:""
    
    }
  },
  methods:{
    save(){
      this.show = false
      let url = this.avatar
      // console.log(url)
      let name = new Date().getTime()+".png"
      // console.log(name)
      if(url == ''){
        url = require('../../assets/user_1.jpg')
      }
      downloadfile(url,name)
    },

    afterRead(file){
      console.log(file)
      this.show = false
      file.status = 'uploading';
      file.message = '上传中...';
      // console.log(file)
      upfilemeth(file.file).then((res)=>{
        let avatar_url = res
        this.$http.post("/user/v1/personal_info/editPersonalInfo",{reqType:"editPersonalInfo",headimg_url:avatar_url})
        .then((res)=>{
          res = JSON.parse(res.data)
          // console.log(res)
          if(res.code == 0){
            Toast.success("上传成功")
              this.$router.go(-1)
          }else{
            Toast.fail(res.msg)
          }
        })
        .catch((e)=>{console.log(e)})
      })
    },

    editava(){
      this.show = true
    },
   
    
  },
  
  mounted(){
    let avatar = this.$route.query.avatar
    this.avatar = avatar
    this.sex = this.$route.query.sex
    // console.log(avatar)
  },
}
</script>
<style scoped>
.tab{
  text-align: center;
  line-height:1rem;
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  border-bottom: 0.01rem solid #ddd;
  margin:0 0.2rem
}
.header{
  height:1rem;
 display:flex;
 align-items: center;
 justify-content: space-between;
  background-color: #fff;
  padding:0 0.2rem;
 
}
.title{
  font-size: 0.34rem;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #333333;
}
.imgstyle{
  width:5rem;
  height:5rem;
  border-radius:50%;
}
.upload{
  border:none;
  border-bottom:0.01rem solid #ddd;
}
</style>