export default (url,name="默认名称.png")=>{
	if(typeof url!="string"||typeof name!="string"){
		throw "你需要传入字符串类型"+"\n"+"你需要传入两个参数"+"\n"+"第一个参数：文件url"+"\n"+"第二个参数：下载后的文件名称"+"\n"+"请输入文件路径"+"\neg:methodName(www.test.com,'test.png')";
	}
	if(!url){
		throw "请输入文件路径"+"\neg:methodName(www.test.com,'test.png')";
	}
    var downobj = {
      canvas:"",
      imgw:"",
      imgh:"",
      can:null,
      init() {
        this.can = document.createElement("canvas");
		this.can.setAttribute("style","position: absolute;left: -10rem;top: 0;")
        this.canvas = this.can;
        document.body.appendChild(this.canvas);
        var ctx = this.canvas.getContext("2d");
        var img = new Image();
        img.onload = () => {
          this.canvas.width = img.width;
          this.canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          this.downmeth();
        };
        img.src = url;
      },
      downmeth() {
        let a = document.createElement("a");
        let event = new MouseEvent("click");
        a.download = name;
        let base64msg = this.canvas.toDataURL("image/jpg");
        let base = base64msg.split(",")[1];
        let base2 = window.atob(base);
        let byte = new Uint8Array(base2.length);
        for (var i = 0; i < base2.length; i++) {
          byte[i] = base2.charCodeAt(i);
        }

        // 生成Blob对象（文件对象）
        let imgbolb = new Blob([byte], { type: "image/jpg" });
        let urls = URL.createObjectURL(imgbolb);
        a.href = urls;
        a.dispatchEvent(event);
        this.can = null;
        document.body.removeChild(this.canvas);
      },
    };
    downobj.init();
}